<template>
  <div id="container">
    <div id="content">
      <!-- start imported html -->
      <h1>Find a record, or create a new record</h1>
      <div class="panel" style="padding-bottom:20px">
        <div id="autocomplete_search_form">
          <label>To find an existing Student or Guest record, enter any part of their name, uni, or email
            address and press DOWN and then ENTER to select a record.</label><br>
          <el-autocomplete autofocus class="inline-input" v-model="state"  :fetch-suggestions="querySearch"
                           placeholder="Please Input" :trigger-on-focus="false" @select="handleSelect"
                           size="60" style="width:500px;"></el-autocomplete>
        </div>

      </div>

      <div class="panel" style="padding-bottom:20px">

        <label>If you can’t find the record above, or wish to add a new Student, Housing, or Guest record,
          enter the Uni and full first name and last name to avoid adding duplicates. All matches to any
          field are returned.</label>
        <el-form :inline="true" :model="searchForm" label-position="left" style="width: auto">
          <el-form-item label="PID" label-width="25px">
            <el-input v-model="searchForm.pid" placeholder="PID" size="mini" style="width: 10em"></el-input>
          </el-form-item>
          <el-form-item label="or UNI" label-width="41px">
            <el-input v-model="searchForm.uni" placeholder="UNI" size="mini" style="width: 10em"></el-input>
          </el-form-item>
          <el-form-item label="or First" label-width="50px">
            <el-input v-model="searchForm.first" placeholder="First" size="mini" style="width: 10em"></el-input>
          </el-form-item>
          <el-form-item label=", Last" label-width="38px">
            <el-input v-model="searchForm.last" placeholder="Last" size="mini" style="width: 10em"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="handleSearch">Search</el-button>
          </el-form-item>
        </el-form>

        <label>Enter the pid, uni, or full first name and last name here to create a new Doris record.</label>
      </div>


      <a :href="'/student/status-data'">Status Data</a><br>
      <!--<a :href="'/student'">HA Students</a><br>-->
<!--      <a :href="'/faculty'">To search or create new faculty records</a>-->
      <!--end imported html-->

    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: 'Find a record'
      }
    },
    components: {
    },
    data() {
      return {
        students: [],
        state: '',
        searchForm : {
          pid : '',
          uni : '',
          first : '',
          last : ''
        }
      }
    },
    methods: {
      querySearch(queryString, cb) {
        axios.get("/api/student/search/?query=" + queryString)
          .then(response => {
            cb(response.data.rows);
          });
        // call callback function to return suggestions
      },
      handleSelect(item) {
        this.$router.push({ path: '/student/profile/' + item.id });
      },
      handleSearch() {
        this.$router.push({
          path: '/search/list',
          query: {
            first_name: this.searchForm.first,
            last_name: this.searchForm.last,
            pid: this.searchForm.pid,
            uni: this.searchForm.uni
          }});
      },
      displayError: function (msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
</style>
<style>
  #content .el-form-item {
    margin-right: 5px;
  }
  #content .el-form-item__label{
    padding: 0px;
    margin-right:5px;
  }
</style>
