<template>
    <div id="container">
        <div id="content">
            <h1>Student search results - {{total}} total</h1>
            <p><a :href="'/student/new/' + paramString">Create
                a new student record</a></p>
            <p class="search-criteria"><b>Your search criteria:</b>
                <span v-if="params.pid">PID: {{ params.pid }}</span>
                <span v-if="params.uni">UNI: {{ params.uni }}</span>
                <span v-if="params.first_name">First Name: {{ params.first_name }}</span>
                <span v-if="params.last_name">Last Name: {{ params.last_name }}</span>
            </p>

            <el-table :default-sort="{prop: 'uni', order: 'ascending'}" :data="sortedData" >
                <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="75px"></el-table-column>
                <el-table-column prop="first_name" label="First" sortable :sort-orders="['ascending', 'descending']"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.id">{{ scope.row.first_name }}</a></template></el-table-column>
                <el-table-column prop="last_name" label="Last" sortable :sort-orders="['ascending', 'descending']"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.id">{{ scope.row.last_name }}</a></template></el-table-column>
                <el-table-column prop="preferred_name" label="Preferred Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="school" label="School" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="program" label="Program" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="coordinator" label="Coordinator" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="ods_status" label="ODS Status" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="expiration_date" label="Expiration Date" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="expected_graduation" label="Expected Graduation" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="disabilities" label="Disabilities" sortable :sort-orders="['ascending', 'descending']"><template slot-scope="scope"><p class="no-margin" v-for="disability in scope.row.disabilities" :key="disability.id">{{ disability }}</p></template></el-table-column>
                <el-table-column prop="accommodations" label="Accommodations" sortable :sort-orders="['ascending', 'descending']" min-width="115px"></el-table-column>
                <el-table-column prop="most_recent_term" label="Most Recent Term" sortable :sort-orders="['ascending', 'descending']" width="60px"></el-table-column>
                <el-table-column prop="future_term" label="Future Term" sortable :sort-orders="['ascending', 'descending']" width="60px"></el-table-column>
                <el-table-column prop="intake_note" label="Intake Note" sortable :sort-orders="['ascending', 'descending']" min-width="115px"><template slot-scope="scope"><p class="intake-note" v-for="note in scope.row.intake_notes" :key="note.id"><b>{{ note.title }}</b><br>{{ note.body }}</p></template></el-table-column>
            </el-table>

            <p><a :href="'/student/new/' + paramString">Create
                a new student record</a></p>
            <p>
                <a :href="'/student/csv/' + paramString">Download
                    as CSV</a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({{total}} student records)</p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: 'Search Results'
            }
        },

        components: {
        },
        data() {
            return {
                paramString: '',
                params: {},
                items: [],
                total: 0,
                sortKey: 'score',
                sortOrders: { 'score': -1}
            }
        },
        computed: {
            sortedData: function () {
                let sortKey = this.sortKey;

                if (!sortKey) {
                    sortKey = 'updated_at';
                }

                let order = this.sortOrders[sortKey] || 1;
                let data = this.items;

                if (sortKey) {
                    data = data.slice().sort(function (a, b) {
                        a = a[sortKey];
                        b = b[sortKey];
                        return (a === b ? 0 : a > b ? 1 : -1) * order;
                    })
                }

                return data;
            }
        },
        methods: {
            displayError: function (msg) {
                this.$message({
                    showClose: true,
                    duration: 10000,
                    type: 'error',
                    message: msg,
                    customClass: 'error-msg'
                });
            },
            load: function () {
                this.paramString = location.search;
                this.params = this.$route.query;
                axios
                    .get('/api/student/list/', { params: this.params })
                    .then(response => {
                        this.items = response.data.rows;
                        this.total = response.data.total;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted() {
            this.load()
        }
    }
</script>

<style lang="postcss" scoped>
    .error-msg p {
        color: red;
    }

    .no-margin {
        margin: 0;
    }

    .intake-note {
        margin-top: 0;
    }

    #content .el-table .cell {
        padding: 1px;
    }

    .el-table {
        font-size: 13px;
    }

    .el-table td {
        vertical-align: top;
    }
</style>
